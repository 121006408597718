import React from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import emailjs from 'emailjs-com';


export default function ContactForm() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_cv6uffg', 'template_gsiatce', e.target, 'user_OTlrObmDjunRZlfmQ2sJU')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return (
    <Form className="contact-form" onSubmit={sendEmail} style={{color:'#E7DFDD'}}>
        <Row>
            <Col>
                <Form.Control type="text" placeholder="Name" name="name" />
            </Col>
            <Col>
                <Form.Control type="email" placeholder="Email" name="email" required />
            </Col>
        </Row>
        <br/>
        <Row>
            <Col>
                <Form.Control as="textarea" rows={3} placeholder="Message" name="message" required/>
            </Col>
        </Row>
        <br/>
        <Row>
            <Col>
                <Button type="submit" target="blank" size='lg' style={{backgroundColor: '#4717F7', borderColor: '#4717F7'}}>Send Message</Button>{' '}
            </Col>
        </Row>
    </Form>
  );
}

