import { React, useEffect } from 'react';
import './App.css';

import NavBar from './components/NavBar'
import Homepage from './components/Homepage'
import About from './components/About'
import Services from './components/Services'
import Contact from './components/Contact'
import Logos from './components/Logos'

import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap design



import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-59ZHVC9'
}

TagManager.initialize(tagManagerArgs)

function App() {
  
  useEffect(() => {
    
  }, [])

  return (
    <div id='home' className="App">
      <NavBar />
      <Homepage />
      <Services />
      <Logos />
      <About />
      <Contact />
      
    </div>
  );
}

export default App;