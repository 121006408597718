import React from "react";
import { Container, Col, Row, Button } from 'react-bootstrap';
import { MdEmail } from 'react-icons/md';
import { AiFillPhone } from 'react-icons/ai';
import { FaLinkedin, FaFacebook } from 'react-icons/fa';

import ContactForm from './ContactForm'

function Contact() {
    return (
        <div id='contact' style={{ backgroundColor: '#0E0B16' }}>
            <br /><br /><br /><br />
            <Container>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <h6 style={{ color: '#4717F7' }}>CONTACT</h6>
                        <h2 style={{ color: '#E7DFDD' }}>Contact Me</h2>
                        <hr style={{
                            width: '100px',
                            margin: '0 auto',
                            borderColor: '#E7DFDD',
                            marginTop: '40px',

                            border: 'thin solid #E7DFDD'
                        }} />
                    </Col>
                </Row>
                <br /><br />
                <Row>
                    <Col sm style={{ textAlign: '', color: '#E7DFDD' }}>
                        <h3>Have an exciting opportunity or a new project? Let's talk.</h3>
                        <br /><br />
                    </Col>
                </Row>
                <Row>
                    <Col sm='3'></Col>
                    <Col sm="6">
                        <ContactForm />
                    </Col>
                    <Col sm='3'></Col>
                </Row>
                <br /><br /><br /><br /><br />
                <Row>
                    <Col>
                        <Button id='facebook-icon' href='https://www.facebook.com/eliranider' target="blank" size='lg' style={{ backgroundColor: '#4717F7', borderColor: '#4717F7' }}><FaFacebook id='facebook-icon' size={25} /></Button>{' '}
                        <Button id='linkedin-icon' href='https://www.linkedin.com/in/eliranider/' target="blank" size='lg' style={{ backgroundColor: '#4717F7', borderColor: '#4717F7' }}><FaLinkedin id='linkedin-icon' size={25} /></Button>{' '}
                        <Button id='email-icon' href='mailto:eliranider@gmail.com' target="blank" size='lg' style={{ backgroundColor: '#4717F7', borderColor: '#4717F7' }}><MdEmail id='email-icon' size={25} /></Button>{' '}
                        <Button id='phone-icon' href='tel:+13522137879' target="blank" size='lg' style={{ backgroundColor: '#4717F7', borderColor: '#4717F7' }}><AiFillPhone id='phone-icon' size={25} /></Button>
                    </Col>
                </Row>
            </Container>
            <br />
        </div>
    )
}

export default Contact