import React from "react";
import { Container, Navbar } from 'react-bootstrap';
import { Command } from 'react-bootstrap-icons';
import { Link } from "react-scroll"; // scrolling effect


function NavBar() {

    return (
        <>
        <Navbar sticky="top" style={{ direction: 'ltr', textAlign: 'right', backgroundColor: '#0E0B16', color: '#0E0B16'}}>
            <Container>
                <Navbar.Brand style={{color: '#0E0B16' }}><Command /></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Link style={{color: '#E7DFDD' }} smooth to="home">Home</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Link style={{color: '#E7DFDD' }} smooth to="services">Services</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Link style={{color: '#E7DFDD' }} smooth to="about">About</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Link style={{color: '#E7DFDD' }} smooth to="contact">Contact</Link>
                </Navbar.Collapse>
                
            </Container>
        </Navbar>
        </>
    )
}

export default NavBar