import React from "react";
import { Container, Col, Row, ProgressBar, Button } from 'react-bootstrap';
import Resume_Eliran_Niderhofer from '../static/files/Resume_Eliran_Niderhofer.pdf'

function About() {

    /*
    import ReactGA from 'react-ga'

    const downloadResume = ()=> {
        alert('Check')
        ReactGA.event({
            category: 'Click',
            action: 'Download resume'
        })
        alert('Check')
    }
    */

    return (
        <>
            <div id='about'>
                <Container>
                    <br />
                    <Row className="justify">
                        <Col sm='6' style={{ textAlign: 'left', color: '#0E0B16' }}>
                            <br /><br /><br />
                            <Row>
                                <Col>
                                    <h6 style={{ color: '#A239CA' }}>ABOUT</h6>
                                    <h2>About Me</h2>
                                </Col>
                            </Row>
                            <br />
                            <div>
                                <div>
                                    The world of marketing is my playground. There is nothing I enjoy more than rolling up my sleeves, challenging
                                    myself, and developing creative solutions to problems. I love that marketing is 24/7 -- the activity is fast-paced and
                                    constant. The environment provides me an opportunity to exercise my strongest skills, like attention to detail, to
                                    analyze data and share insights, trends, knowledge and inspiration, all while working with talented people & teams
                                    that motivate me to always continue to learn, develop and innovate.
                            <br /><br />
                                </div>

                                <div> {/* className="d-none d-lg-block"  <> to hide section on mobile */}
                            Growing up in Israel, I was always passionate and curious about people and human behavior. I began learning about
                            the power of teamwork and organization as a kid in the scouts, where I spent ten years of my childhood. After studying
                            psychology and sociology in high school, I served in the IDF Air-Force as a lieutenant officer and an F-16 technician,
                            and received a certification of appreciation for the discovery of a technical problem that shut down the entire
                            squadron. After my service, I traveled for 3 months in South America, hiking and exploring new people and cultures.
                            <br /><br />
                                </div>

                                <div>
                                    Over the years I discovered that marketing allows me to perfectly combine my interest in psychology and technology,
                                    which prompted me to start my marketing & management degree at The Open University. In 2014, I got my first
                                    marketing position in Tel-Aviv, and since then I’ve led a mobile marketing team and managed programmatic and social
                                    ads in advertising agencies. In 2018 I moved to Florida, where I worked directly with clients and business owners to
                                    plan and execute marketing strategies on online and offline media channels.
                            <br /><br />
                                </div>

                                <div>
                                    Outside of work, I enjoy all kinds of people, love animals, nature, and am always looking for running or hiking partners.
                                    Any opportunity I have, I hang out with family and friends, listen to music, and cook a lot of middle eastern food. I am
                                    also working on side programming projects in Python and React, and before covid hit I used to practice kickboxing
                                    and travel more.
                            <br /><br /><br />
                                </div>
                            </div>
                            {/*<Button href={Resume_Eliran_Niderhofer} target="blank" size='lg' style={{ backgroundColor: '#A239CA', borderColor: '#A239CA' }}>Download CV</Button>*/}
                            <br /><br />
                        </Col>
                        <Col sm='1' style={{ textAlign: 'left', color: '#0E0B16' }}></Col>
                        <Col sm='5' style={{ textAlign: 'left', color: '#0E0B16' }}>
                            <br /><br /><br />
                            <center><h3 style={{ color: '#A239CA' }}>Skills</h3></center>
                            <br /><br />
                            <h6>Project Management</h6>
                            <div className="d-none d-lg-block" style={{ fontSize: '12px', fontStyle: 'italic' }}>Includes TargetProcess, Asana, Monday.com, Later.com</div><ProgressBar now={100} label={''} /><br />
                            <h6>Data Analytics</h6>
                            <div className="d-none d-lg-block" style={{ fontSize: '12px', fontStyle: 'italic' }}>Includes Excel, DCM, TapClicks, Tag Manager, Google Analytics, SQL, etc.</div><ProgressBar now={75} label={''} /><br />
                            <h6>Programmatic Media Buying</h6>
                            <div className="d-none d-lg-block" style={{ fontSize: '12px', fontStyle: 'italic' }}>Includes DV360, Mediamath, TTD, Facebook Ads, etc.</div><ProgressBar now={100} label={''} /><br />
                            <h6>Social Media Management</h6>
                            <div className="d-none d-lg-block" style={{ fontSize: '12px', fontStyle: 'italic' }}>Includes Buffer, Hootsuite, Later, etc.</div><ProgressBar now={75} label={''} /><br />
                            <h6>UX/UI and Ad Design</h6>
                            <div className="d-none d-lg-block" style={{ fontSize: '12px', fontStyle: 'italic' }}>Includes Adobe Spark, Canva, Photoshop</div><ProgressBar now={90} label={''} /><br />
                            <h6>Copy Writing</h6>
                            <div className="d-none d-lg-block" style={{ fontSize: '12px', fontStyle: 'italic' }}>Includes Marketing copywriting, Brand copywriting, SEO copywriting </div><ProgressBar now={65} label={''} /><br />
                            <h6>SEO/SEM</h6>
                            <div className="d-none d-lg-block" style={{ fontSize: '12px', fontStyle: 'italic' }}>Includes Google Ads, Website Grader, Google Search Console, SEMrush, etc.</div><ProgressBar now={60} label={''} /><br />
                            <h6>Machine Learning</h6><ProgressBar now={25} label={''} /><br />
                            <h6>Python</h6><ProgressBar now={50} label={''} /><br />
                            <h6>Detail-Oriented</h6>
                            <ProgressBar now={100} label={''} /><br />
                            <div style={{ textAlign: 'right', fontSize: '12px', fontStyle: 'italic' }}>
                                Beginner - Intermediate - Advanced - Expert *
                        </div><br />
                        </Col>
                    </Row>
                </Container>
                <br /><br />
            </div>
        </>
    )
}

export default About