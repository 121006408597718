import React from "react";
import { Container, Col, Row } from 'react-bootstrap';

import Att2 from '../static/photos/Att.png'
import Verizon from '../static/photos/Verizon.png'
import DV360 from '../static/photos/DV360.png'
import TapClicks from '../static/photos/Tapclicks.png'
import GoogleAds from '../static/photos/GA2.png'

function Logos() {
    return (
        <div id='logos' style={{ backgroundColor: '#0E0B16' }}>
            <br /><br />
            <Container>
                <Row>  {/*Already partnered with:*/}
                    <Col><img className='img-fluid' style={{ maxWidth: '30%' }} alt='Google Ads' src={GoogleAds} /></Col>
                    <Col><img className='img-fluid' style={{ maxWidth: '95%' }} alt='Att' src={Att2} /></Col>
                    <Col><img className='img-fluid' style={{ maxWidth: '110%' }} alt='DV360' src={DV360} /></Col>
                    <Col><img className='img-fluid' style={{ maxWidth: '60%' }} alt='Verizon' src={Verizon} /></Col>
                    <Col><img className='img-fluid' style={{ maxWidth: '95%' }} alt='TapClicks' src={TapClicks} /></Col>

                </Row>
            </Container>
            <br /><br />
        </div>
    )
}

export default Logos