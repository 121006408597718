import React from "react";
import { Container, Col, Row } from 'react-bootstrap';


function Homepage() {
    return (
        <div style={{backgroundColor: '#0E0B16'}}>
        <Container>
        <Row>
            <Col sm={9} style={{textAlign:'left', color: '#E7DFDD'}}>
                <br/><br/><br/><br/>

                <h1>Hello,</h1>
                    
                <hr style={{width:'120px', 
                            borderColor:'#A239CA',  
                            marginLeft:'0', 
                            marginTop:'40px', 
                            marginBottom:'30px',
                            border: 'thin solid #A239CA'}}/>

                <h3>Brand success. Media planning. Programmatic ads.</h3><br/>
                

                <p className="home-header3">
                    I'm <span style={{color:'#A239CA'}}>Eliran </span> 
                    Niderhofer, a data-driven marketing expert.<br/>
                    I drive processes for brands and marketing agencies to establish 
                    thought leadership, and create and implement successful marketing strategies.
                </p>
                <br/>

                <p className="home-header4">
                    Check out my free marketing consultation for small businesses.
                </p>

                <br/><br/><br/><br/><br/><br/>
            </Col>
            <Col></Col>
        </Row>
        </Container>
        </div>
    )
}

export default Homepage