import React from "react";
import { Container, Col, Row, Card } from 'react-bootstrap';
import { GrPlan } from 'react-icons/gr';
import { VscSettings } from 'react-icons/vsc';
import { FaMapSigns } from 'react-icons/fa';
import { GiTalk } from 'react-icons/gi';

function Services() {
    return (
        <div id='services' style={{backgroundColor: '#E7DFDD'}}>
            <br/><br/><br/><br/>
        <Container>
        <Row>
            <Col>
                <h6 style={{color:'#4717F7'}}>SERVICES</h6>
                <h2>What I Do</h2>
            </Col>
        </Row>
        <br/><br/>
        <Row>
            <Col sm={4} style={{textAlign:'left'}}> 
                <Card><br></br>
                    <Container>
                        <Row>
                            <Col className='default'>
                                <FaMapSigns size={35}/><br/><br/>
                                <p className='subtitle'>Brand Strategy</p>
                                My process starts with
                                <span style={{color:'#4717F7'}}> building </span>
                                a clear picture of a brand’s overall business strategy by defining their goals, values,
                                target clients, and competitors and aligning them with the business’s objectives. Then, I 
                                <span style={{color:'#4717F7'}}> research </span> the brand's
                                competitors and target audience to understand their perspective, priorities, and needs to develop a well differentiated
                                market positioning statement, as well as the brand’s voice, language, and message. All the while, I constantly 
                                <span style={{color:'#4717F7'}}> develop </span>
                                the brand's marketing toolkit, content strategy, website, SEO, and social media channels to continue to 
                                <span style={{color:'#4717F7'}}> improve </span>
                                tracking and measurement and draw conclusions that allow us to effectively adjust our strategy in light of the dynamic
                                short- and long-term objectives.
                                <br></br><br></br>
                            </Col>
                        </Row>    
                    </Container>
                </Card>
            </Col>
            <Col sm={4} style={{textAlign:'left'}}> 
                <Card><br></br>
                <Container>
                    <Row>
                        <Col className='default'>
                            <GrPlan size={35}/><br/><br/>
                            <p className='subtitle'>Media Planning</p>
                                Before anything else, I 
                                <span style={{color:'#4717F7'}}> define </span>
                                SMART goals and objectives to measure our success. I then 
                                <span style={{color:'#4717F7'}}> study </span>
                                my competitors and
                                <span style={{color:'#4717F7'}}> conduct </span>
                                market research to better understand my targeted audience and the best customers. This  includes reviewing
                                customer interests and buyer personas in order to determine what media will reach and convert them. After that I
                                <span style={{color:'#4717F7'}}> adjust </span> 
                                our budgets, time, frequency, key messaging points, and the perfect media mix between online, TV, print, radio,
                                etc. Lastly, I 
                                <span style={{color:'#4717F7'}}> execute </span> 
                                the plan while continuing to track and analyze results to measure success, and improve the media plan.
                            <br></br><br></br>
                        </Col>
                    </Row>
                    </Container>
                </Card>
            </Col>
            <Col sm={4} style={{textAlign:'left'}}> 
                <Card><br/>                  
                <Container>
                    <Row>
                        <Col className='default'>
                            <VscSettings size={35}/><br/><br/>
                            <p className='subtitle'>Programmatic Buying</p>
                            I begin by 
                            <span style={{color:'#4717F7'}}> identifying </span>
                            first-party data and target audiences to determine the best way to reach them. I 
                            <span style={{color:'#4717F7'}}> investigate </span> my
                            current customers and distribute the audience into segments to map who they are, and where they are to be able to
                            <span style={{color:'#4717F7'}}> tailor </span> 
                            a message, and start plan A/B testing. Then, I research competitors' activity, understanding what they do, what
                            works for them and what does not. I create campaigns that correspond with our goals, include bids and budgets, or
                            <span style={{color:'#4717F7'}}> negotiate </span>
                            desired PMP deals. Lastly, I insure media delivery, review reports for strengths and weaknesses, and
                            <span style={{color:'#4717F7'}}> analyze </span>
                            effectiveness to optimize towards our KPI goals, allocate budgets, retarget, 
                            <span style={{color:'#4717F7'}}> share </span> 
                            insights, report and make recommendations. 
                            <br></br><br></br>
                        </Col>
                    </Row>
                    </Container>
                </Card>
            </Col>
        </Row>
        <br></br>
        <Row>
        <Col sm style={{textAlign:'left'}}> 
                <Card><br></br>
                <Container>
                    <Row>
                        <Col className='default'>
                            <GiTalk size={40}/><br/><br/>
                            <p className='subtitle'>Consultation</p>
                            Own a small business? <span style={{color:'#4717F7'}}>Schedule </span>
                             a free consultation to review your brand's products, voice, audiences. 
                            I will provide you with relevant insights, and the next steps for your marketing strategy.
                            <span style={{color:'#4717F7'}}> Learn </span>
                            how to analyze your marketing efforts, leverage your existing marketing efforts with analytics and SEO tools, 
                            and what you can do right now to improve your performance.
                            <span style={{color:'#4717F7'}}> Bring </span>
                             it all together to a solid identity that your customers can relate to, engage with, and improve your ROI. 
                            <br/><br/>
                            <p>Previously consulted: hair salons, real estate, online teaching, laundry services, dealerships, and many more.</p>
                        </Col>
                    </Row>
                    </Container>
                </Card>
            </Col>
        </Row>
        </Container>
        <br/><br/><br/>
        </div>
    )
}

export default Services